import React, { useState, useEffect } from "react";
import "./CompetitionDetails.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";

function CompetitionDetails() {
  const { questionId } = useParams();

  const [question, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [comment, setComment] = useState("");
  const [refresh, setRefersh] = useState(false);

  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("tadawl-userToken");

  const userImg = localStorage.getItem("tadawl-userImg");
  const userName = localStorage.getItem("tadawl-userName");

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/competition/show/${questionId}`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
          token: token,
        },
      });
      setQuestion(result.data.data.competition);
      setAnswers(result.data.data.answers);
    })();
  }, [refresh, token]);

  const addComment = () => {
    (async () => {
      try {
        const result = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_BASE_URL}/TDAWL/competition/store/answers/${questionId}`,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
            token: token,
          },
          data: { answer: comment },
        });
        setRefersh(!refresh);
        setComment("");
        successNotify(result.data.message);
      } catch (err) {
        ErrorNotify(err.response.data.message);
      }
    })();
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const handleClose = () => {
    setComment("");
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Cairo" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Cairo" }}>
          {msg === "Unauthenticated." ? "يجب تسجيل الدخول اولا" : msg}
        </h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  return (
       <>
      <div className="performance-details-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            /
            <Link className="back-home" to="/competition">
              الاسئلة
            </Link>
            / <span>الاجابات</span>
          </h5>
        </div>
      </div>
    <div className="question-details py-5">
      {loading ? (
        <div className="loading-carsoul loading text-center">
          <div className="spinner-grow text-dark" role="status"></div>
          <div className="spinner-grow text-warning" role="status"></div>
          <div className="spinner-grow text-dark" role="status"></div>
        </div>
      ) : (
        <Container>
          <div className="card card-comp flex-column ">
            <div className="d-flex justify-content-between align-items-center mb-4 mob-question">
              <div className="me-4">
                <h1>{question.title}</h1>
                <h5>{question.question}</h5>
              </div>
              {question.image && (
                <div>
                  <img
                    style={{
                      width: "300px",
                      height: "200px",
                      borderRadius: "5px",
                    }}
                    src={question.image}
                    alt=""
                  />
                </div>
              )}
            </div>

            <div className="bg-light p-2">
              <div className="d-flex flex-row align-items-center">
                <img className="rounded-circle me-3" src={userImg} width="50" />
                <textarea
                  className="form-control ml-1 shadow-none textarea mx-4 py-4"
                  value={comment}
                  onChange={handleComment}
                  placeholder="اكتب هنا"
                ></textarea>
              </div>
              <div class="my-4 d-flex justify-content-end">
                <button
                  onClick={handleClose}
                  className="btn btn-outline-primary btn-sm ml-1 shadow-none"
                  type="button"
                >
                  الغاء
                </button>
                <button
                  onClick={addComment}
                  className="btn btn-sm shadow-none mx-3 "
                  type="button"
                >
                  أضف اجابتك
                </button>
              </div>
            </div>

            <div>
              <h6 style={{ margin: "20px 0" }}>الاجابات</h6>
              {[...answers].reverse().map((item) => (
                <div
                  key={item.id}
                  className="answers d-flex justify-content-between align-items-center"
                >
                  <div>
                    <h2>{userName}</h2>
                    <h4>{item.answer}</h4>
                  </div>

                  <div className="comment-date me-4">
                    <p style={{ direction: "ltr" }}>
                      {moment(item.created_at).fromNow()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      )}
    </div></>
  );
}

export default CompetitionDetails;
