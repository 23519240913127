import React, { useState, useEffect } from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import "./AdvantageTrust.css";
import { Fade, Zoom } from "react-reveal";
import axios from "axios";
function AdvantageTrust() {
  const [advantageData, setAdvantageData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/features`,
        headers: "application/json",
      });

      const iconsArr = [
        "fas fa-yin-yang",
        "fas fa-coins",
        "fab fa-buffer",
        "fas fa-share-alt",
        "fas fa-yin-yang",
        "fas fa-coins",
        "fab fa-buffer",
        "fas fa-share-alt",
      ];


      const arrNew = result.data.data.map((object, i) => {
        return { ...object, icon: iconsArr[i] };
      });
      setAdvantageData(arrNew);

      setLoading(false);
    })();
  }, []);

  return (
    <div className="advantage-trust" id="about">
      <Container>
        <h4>.:: مميزات ABC تداول ::.</h4>

        <p className="private-text">بعض من مميزات ABC تداول علي سبيل المثال</p>
        {loading ? (
          <div className="abc-loading mt-5">
            <lord-icon
              src="https://cdn.lordicon.com/avytqtql.json"
              trigger="loop"
              colors="primary:#329292,secondary:#d1f3fa"
              style={{ width: "60px", height: "60px" }}
            ></lord-icon>
          </div>
        ) : (
          <Zoom>
            <Row>
              {advantageData.map((item) => (
                <Col className="mt-4 mb-4" xs={12} md={6} lg={4} key={item.id}>
                  <div className="card services-card">
                    <i className={`${item.icon} advantage-icon`}></i>

                    <div className="mt-5 me-3 ms-3">
                      <h6>{item.name}</h6>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Zoom>
        )}
      </Container>
    </div>
  );
}

export default AdvantageTrust;
