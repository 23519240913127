import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "./SideBar.css";
function SideBar() {
  const [hide, setHide] = useState(true);
  const [activeLink, setActiveLink] = useState(1);
  return (
    <div className="side-bar">
      <div className={activeLink === 1 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/"
          className="links"
        >
          الرئيسية
        </Link>
      </div>
      <div className={activeLink === 6 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(6);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/services"
          className="links"
        >
          الخدمات
        </Link>
      </div>
      <div className={activeLink === 2 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(2);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/packges"
          className="links"
        >
          الباقات
        </Link>
      </div>
      <div className={activeLink === 3 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(3);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/performance-rate"
          className="links"
        >
          معدل الأداء
        </Link>
      </div>
      {/* <div className={activeLink === 4 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(4);
          }}
          to="/your-issue"
          className="links"
        >
          مشكلتك
        </Link>
      </div> */}

      <div className={activeLink === 7 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(7);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/news"
          className="links"
        >
          الاخبار
        </Link>
      </div>
      <div className={activeLink === 5 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(5);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/trust-rate"
          className="links"
        >
          التقييم
        </Link>
      </div>
      <div className={activeLink === 9 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(9);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/competition"
          className="links"
        >
          اراء محللين
        </Link>
      </div>

      <div className={activeLink === 4 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(4);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/training"
          className="links"
        >
          تدريب
        </Link>
      </div>
      <div className={activeLink === 8 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(8);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/about-us"
          className="links"
        >
          عن ABC تداول
        </Link>
      </div>
      <div className={activeLink === 10 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(10);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          to="/contact-us"
          className="links"
        >
          اتصل بنا
        </Link>
      </div>
    </div>
  );
}

export default SideBar;
