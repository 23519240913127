import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./YourIssue.css";
import { IoIosCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Fade } from "react-reveal";

function YourIssue() {
  return (
    <>
      <div className="your-issue-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>مشكلتك</span>
          </h5>
        </div>
      </div>
      <div className="your-issue">
        <Container className="container-body">
          <Row className="align-items-center py-5">
            <Col
              md={7}
              className="d-flex align-items-start justify-content-center flex-column"
            >
              <Fade>
                <div className="pb-5 contact-info">
                  <h6>
                    شركة ثقة بتساعدك تحل مشكلتك في السوق سجل مشكلتك
                    وبيتم التواصل معك
                  </h6>
                </div>

                <div className="d-flex justify-content-center align-items-center my-3">
                  <IoIosCall className="information-icons" />
                  <h6>20123456789+</h6>
                </div>
                <div className="d-flex justify-content-center align-items-center my-3">
                  <FaEnvelope className="information-icons2" />
                  <h6>support@thiqa.com</h6>
                </div>
                <div className="d-flex justify-content-center align-items-center my-3">
                  <MdLocationOn className="information-icons" />
                  <h6>address</h6>
                </div>
              </Fade>
            </Col>
            <Col md={5}>
              <Fade>
                <div
                  style={{
                    margin: "30px 0",
                  }}
                  className="freelogin-sec "
                >
                  <h5>ارسل مشكلتك</h5>
                  <div className="freelogin-inputs problems">
                    <input type="text" placeholder="الاسم" />
                    <input type="number" placeholder="رقم الجوال" />
                    <textarea
                      name=""
                      id=""
                      cols="30"
                      rows="8"
                      placeholder="اكتب هنا"
                    ></textarea>
                    <button type="button">ارسال</button>
                  </div>
                </div>
              </Fade>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default YourIssue;
