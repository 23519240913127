import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "./NewsComp.css";
import newsImg1 from "../../assests/bg-7.jpg";
import axios from "axios";
import { FcCalendar } from "react-icons/fc";
import { Fade, Zoom } from "react-reveal";
import { HiArrowSmRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function NewsComp() {
  const [newsData, setNewsData] = useState([]);

  const marketId = localStorage.getItem("tadawl-marketId")

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/news/${marketId}`,
        headers: "application/json",
      });
      setNewsData(result.data.data);
    })();
  }, [marketId]);

  return (
    <div className="news-comp">
      <Container>
        <h4>.:: اخر الأخبار ::.</h4>
        <Zoom>
          <Row>
            {newsData.map((item) => (
              <Col xs={12} md={3} key={item.id}>
                <Card className="d-flex flex-column mb-4 news-card">
                  <div style={{ position: "relative" }}>
                    <Card.Img
                      style={{ minHeight: "283px" }}
                      variant="top"
                      src={item.image}
                    />
                    <p className="date-news">
                      <FcCalendar style={{ marginLeft: "5px" }} />
                      {new Date(item.created_at).toLocaleDateString("ar-EG", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </p>
                  </div>
                  <Card.Body>
                    <Card.Title style={{ fontWeight: "bold" }}>
                      {item.title}
                    </Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>

                  <Link
                    style={{ color: "var(--second-color)" }}
                    to={`/news-details/${item.id}`}
                  >
                    <button
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                    >
                      اقرأ أكثر
                    </button>
                  </Link>
                </Card>
              </Col>
            ))}
          </Row>
        </Zoom>
      </Container>
    </div>
  );
}

export default NewsComp;
