import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Pointer.css";
import { Zoom } from "react-reveal";

function Pointer() {
  const [pointerData, setPointerData] = useState({});
  const [loading, setLoading] = useState(false);

  const marketId = localStorage.getItem("tadawl-marketId")

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/pointer/${marketId}`,
        headers: "application/json",
      });
      setPointerData(result.data.data);
      setLoading(false);
    })();
  }, [marketId]);
  return (
    <div className="pointer">
      <h4>.:: المــؤشـر ::.</h4>
      {loading ?  <div className="abc-loading mt-5">
            <lord-icon
              src="https://cdn.lordicon.com/avytqtql.json"
              trigger="loop"
              colors="primary:#329292,secondary:#d1f3fa"
              style={{ width: "60px", height: "60px" }}
            ></lord-icon>
          </div>:  <Container>
        <Row className="align-items-center justify-content-between">
          <Col xs={12}>
            <Zoom>
              <div className="card pointer-card flex-column">
                <img src={pointerData.image} alt="" />
                <p>{pointerData.description}</p>
              </div>
            </Zoom>
          </Col>
        </Row>
      </Container>}

     
    </div>
  );
}

export default Pointer;
