import React, { useState } from "react";
import { Carousel, Col, Container, Row, Table } from "react-bootstrap";
import "./Recommendations.css";
import { useEffect } from "react";
import axios from "axios";
import { Fade, Zoom } from "react-reveal";
import { FaStar } from "react-icons/fa";

function Recommendations() {
  const [dataRecom, setDataRecom] = useState([]);

  const marketId = localStorage.getItem("tadawl-marketId");

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/recommendation/${marketId}`,
        headers: "application/json",
      });
      setDataRecom(result.data.data);
    })();
  }, [marketId]);

  const slider1 = dataRecom.slice(0, 4);
  const slider2 = dataRecom.slice(4, 8);

  return (
    <div className="recommendation">
      <Container>
        <h4>.:: اخر التوصيات الرابحة ::.</h4>

        <Carousel interval={null}>
          <Carousel.Item>
            <div className=" recom-card-parent">
              <Zoom>
                <Row style={{ width: "100%", margin: "0" }}>
                  {slider1.map((item) => (
                    <Col md={3} key={item.id}>
                      <div className="card recom-card flex-column">
                        {item.is_golden === "Golden" && (
                          <FaStar
                            style={{
                              color: "gold",
                              position: "absolute",
                              left: "10px",
                              top: "10px",
                            }}
                          />
                        )}

                        <p>
                          السهم : <span>{item.stock.name}</span>
                        </p>
                        <p>
                          سعر الشراء : <span>{item.purchasing_price}</span>
                        </p>
                        <p>
                          سعر البيع : <span>{item.selling_price}</span>
                        </p>
                        <p>
                          وقف الخسارة : <span>{item.stop_loss_price}</span>
                        </p>
                        <p>
                          تاريخ الشراء :
                          <span>
                            {new Date(item.date_of_purchase).toLocaleDateString(
                              "ar-EG",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </p>
                        <p>
                          تاريخ البيع :
                          <span>
                            {new Date(item.date_of_sale).toLocaleDateString(
                              "ar-EG",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Zoom>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className=" recom-card-parent">
              <Zoom>
                <Row style={{ width: "100%", margin: "0" }}>
                  {slider2.map((item) => (
                    <Col md={3} key={item.id}>
                      <div className="card recom-card flex-column">
                        {item.is_golden === "Golden" && (
                          <FaStar
                            style={{
                              color: "gold",
                              position: "absolute",
                              left: "10px",
                              top: "10px",
                            }}
                          />
                        )}

                        <p>
                          السهم : <span>{item.stock.name}</span>
                        </p>
                        <p>
                          سعر الشراء : <span>{item.purchasing_price}</span>
                        </p>
                        <p>
                          سعر البيع : <span>{item.selling_price}</span>
                        </p>
                        <p>
                          وقف الخسارة : <span>{item.stop_loss_price}</span>
                        </p>
                        <p>
                          تاريخ الشراء :
                          <span>
                            {new Date(item.date_of_purchase).toLocaleDateString(
                              "ar-EG",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </p>
                        <p>
                          تاريخ البيع :
                          <span>
                            {new Date(item.date_of_sale).toLocaleDateString(
                              "ar-EG",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </span>
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>{" "}
              </Zoom>
            </div>
          </Carousel.Item>
        </Carousel>
      </Container>
    </div>
  );
}

export default Recommendations;
