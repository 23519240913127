import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { toast } from "react-toastify";
import axios from "axios";
import "./ClientLogin";
import { useLocation, useParams } from "react-router-dom";

function ClientLogin({setHideWhats}) {
  const params = useParams();

  const [freeForm, setFreeForm] = useState({
    name: "",
    email: "",
    phone: "",
    cash_type: "",
    market_id: "",
    web: 2,
    user_id: +params.marketerId,
  });
  const [submitting, setSubmitting] = useState(false);
  const [marketInfo, setMarketInfo] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/homepage/markets`,
        headers: "application/json",
      });
      const tadawlMarkets = result.data.data.filter((item) => item.web_id === 2);
      setMarketInfo(tadawlMarkets);
    })();
    setHideWhats(false);
  }, []);

  const handleChange = (e) => {
    setFreeForm({ ...freeForm, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        {/* <AiFillCheckCircle style={{ color: "#07bc0c", fontSize: "28px" }} /> */}
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        {/* <RiErrorWarningFill style={{ color: "#e74c3c", fontSize: "28px" }} /> */}
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleFreeRegister = () => {
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/TDAWL/auth/marketer-register`,
        freeForm
      )
      .then((res) => {
        console.log(res);
        setSubmitting(false);
        successNotify(res.data.message);
        setFreeForm({
          name: "",
          email: "",
          phone: "",
          cash_type: "",
          market_id: "",
          web: 2,
          user_id: +params.marketerId,
        });
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error?.response?.data?.message);
        ErrorNotify(error?.response?.data?.message);
      });
  };

  console.log(freeForm);

  return (
    <>
      <Container style={{ margin: "40px auto" }}>
        <div className="freelogin-sec">
        <h5>تسجيل جديد مجاني</h5>
          <div className="freelogin-inputs">
          <h6>سجل معنا واحصل علي افضل النتائج</h6>
            <input
              name="name"
              value={freeForm.name}
              onChange={handleChange}
              type="text"
              placeholder="الاسم"
            />
            <input
              value={freeForm.email}
              name="email"
              onChange={handleChange}
              type="email"
              placeholder="البريد الالكتروني"
            />
            <input
              value={freeForm.phone}
              name="phone"
              onChange={handleChange}
              type="number"
              placeholder="رقم الجوال"
            />
            <Row style={{ margin: "12px 0" }}>
              <Col xs={12} md={6} className="my-3">
                <select
                  name="cash_type"
                  value={freeForm.cash_type}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    اختر السيوله
                  </option>
                  <option value="1">من ٢٠ ال ٥٠</option>
                  <option value="2">من ٥٠ ال ٥٠٠</option>
                  <option value="3">من ٥٠٠ ال مافوق</option>
                  <option value="4">آخري</option>
                </select>
              </Col>
              <Col xs={12} md={6} className="my-3">
                <select
                  name="market_id"
                  value={freeForm.market_id}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    اختر السوق
                  </option>
                  {marketInfo.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Button
              htmlType="submit"
              loading={submitting}
              onClick={handleFreeRegister}
              type="button"
            >
              {!submitting && "تسجيل"}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
}

export default ClientLogin;
