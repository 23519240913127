import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import logo from "../../../assests/abc-logo.png";
import "./NavBarLogin.css";
import { Link } from "react-router-dom";
import { FaUserAlt } from "react-icons/fa";
import Modall from "../../Modal/Modal";
import { MdMenu } from "react-icons/md";
import axios from "axios";

function NavBarLogin({ setIsLoggedIn, setRefresh, refresh }) {
  const [show, setShow] = useState(false);

  const [activeLink, setActiveLink] = useState(1);

  const handleShow = () => setShow(true);

  const [marketInfo, setMarketInfo] = useState([]);

  const [marketName, setMarketName] = useState("");

  const [selected, setSelected] = useState();

  const [hide, setHide] = useState(true);

  const handleHide = () => setHide(!hide);

  const handleClickToggle = () => {
    setHide(!hide);
  };

  const handleMarketChange = (e) => {
    setSelected(e);
    setRefresh(!refresh);
  };

  localStorage.setItem("tadawl-marketId", selected);

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/markets`,
        headers: "application/json",
      });
      const tadawlMarkets = result.data.data.filter((item) => item.web_id === 2);
      setMarketInfo(tadawlMarkets);
      setSelected(tadawlMarkets[0]?.id);
    })();
  }, []);

  console.log(marketInfo)

  return (
    <Navbar
      style={{
        backgroundColor: "var(--second-color)",
        borderBottom: "1px solid var(--main-color)",
      }}
      className="navbar-trust"
      collapseOnSelect
      expand="lg"
    >
      <Container style={{ margin: "0 30px" }}>
        <Link
          className="logo-link"
          to="/"
          onClick={() => {
            setHide(!hide);
            setActiveLink(1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img className="logo" src={logo} alt="" />
        </Link>
        <MdMenu onClick={handleClickToggle} className="toggle-btn" />
        <Navbar.Collapse className={hide ? "hide" : "show"}>
          <Nav className="links-route">
            <div className="nav-route-top">
              <div className={activeLink === 1 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(1);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/"
                  className="links"
                >
                  الرئيسية
                </Link>
              </div>
              <div className={activeLink === 6 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(6);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/services"
                  className="links"
                >
                  الخدمات
                </Link>
              </div>
              <div className={activeLink === 2 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(2);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/packges"
                  className="links"
                >
                  الباقات
                </Link>
              </div>
              <div className={activeLink === 3 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(3);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/performance-rate"
                  className="links"
                >
                  معدل الأداء
                </Link>
              </div>
              {/* <div className={activeLink === 4 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(4);
          }}
          to="/your-issue"
          className="links"
        >
          مشكلتك
        </Link>
      </div> */}

              <div className={activeLink === 7 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(7);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/news"
                  className="links"
                >
                  الاخبار
                </Link>
              </div>

              <div className={activeLink === 5 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(5);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/trust-rate"
                  className="links"
                >
                  التقييم
                </Link>
              </div>
              <div className={activeLink === 9 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(9);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/competition"
                  className="links"
                >
                  اراء محللين
                </Link>
              </div>
              <div className={activeLink === 4 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(4);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/training"
                  className="links"
                >
                  تدريب
                </Link>
              </div>

              <div className={activeLink === 8 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(8);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/about-us"
                  className="links"
                >
                  عن ABC تداول
                </Link>
              </div>
              <div className={activeLink === 10 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(10);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/contact-us"
                  className="links"
                >
                  اتصل بنا
                </Link>
              </div>
            </div>
          </Nav>
          <Nav className="links-route-btns">
            {/* <div className="flags ms-3">
              <select
                name=""
                id=""
                value={selected}
                onChange={handleMarketChange}
                n
              >
                {marketInfo.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div> */}

<div className="flags ms-3">
              <Dropdown>
                <Dropdown.Toggle style={{ backgroundColor:"transparent",border: "1px solid var(--main-color)" ,color:"white" ,padding:"8px" ,borderRadius:"25px"}} variant="success" id="dropdown-basic">
                  {marketName === "" ? (
                    <div className="d-flex align-items-center justify-content-between">
                    <img
                      style={{
                        width: "30px",
                        height: "20px",
                          borderRadius: "5px",
                        marginLeft:"5px"
                      }}
                      src={  marketInfo[0]?.image}
                      alt=""
                    />
                    <p style={{ margin: "0" }}> { marketInfo[0]?.name}</p>
                  </div>
                  
                  ) : (
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        style={{
                          width: "30px",
                          height: "20px",
                            borderRadius: "5px",
                          marginLeft:"5px"
                        }}
                        src={marketName.image}
                        alt=""
                      />
                      <p style={{ margin: "0" }}> {marketName.name}</p>
                    </div>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {marketInfo.map((item) => (
                    <Dropdown.Item key={item.id} style={{ margin: "5px 0" }}
                    onClick={()=>handleMarketChange(item.id)}>
                      <div
                        onClick={() => setMarketName(item)}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "20px",
                            borderRadius: "5px",
                          }}
                          src={item.image}
                          alt=""
                        />
                        <p style={{ margin: "0" }}> {item.name}</p>
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div>
              <button onClick={handleShow} className="btn-login me-3">
                <FaUserAlt className="ms-2" />
                تسجيل دخول
              </button>
              <Modall
                show={show}
                setShow={setShow}
                setIsLoggedIn={setIsLoggedIn}
                type="1"
              />
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarLogin;
