import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Competition.css";
import axios from "axios";
import { Row, Col, Container } from "react-bootstrap";
import { Zoom, LightSpeed } from "react-reveal";
import { FcCalendar } from "react-icons/fc";
import Pagination from "./../../Components/Pagination/Pagination";
import Modall from "./../../Components/Modal/Modal";

function Competition({ setIsLoggedIn }) {
  const [competition, setCompetition] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [perPage, setPerPage] = useState();
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const token = localStorage.getItem("tadawl-userToken");

  const marketId = localStorage.getItem("tadawl-marketId")

  useEffect(() => {
    (async () => {
      setLoading(true);
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/competition/index/${marketId}`,
        headers: {
          Accept: "application/json",
          // Authorization: "Bearer " + token,
          // token: token,
        },
      });
      setCompetition(result.data.data.data);
      setTotalCount(result.data.data.total);
      setPerPage(result.data.data.per_page)
      setLoading(false);
    })();
  }, [marketId]);

  const getPage = async (page) => {
    const result1 = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/TDAWL/competition/index?page=${page}`,
      headers: {
        Accept: "application/json",
        // Authorization: "Bearer " + token,
        // token: token,
      },
    });
    setCompetition(result1.data.data.data);
    setTotalCount(result1.data.data.total);
    setPerPage(result1.data.data.per_page)
  };

  return (
    <>
      <div className="competition-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>الاسئلة</span>
          </h5>
        </div>
      </div>
      <div className="competition">
        <Container className="container-body trust-rate-content">
          <h4>.:: الأسئلة ::.</h4>
          {loading ? (
            <div className="abc-loading mt-5">
              <lord-icon
                src="https://cdn.lordicon.com/avytqtql.json"
                trigger="loop"
                colors="primary:#329292,secondary:#d1f3fa"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
            </div>
          ) : (
            <>
              <div style={{ minHeight: "60vh" }}>
                {competition.length !== 0 ? (
                  competition.map((item) => (
                    <Link
                      key={item.id}
                      to={`/competition/${item.id}`}
                      className="link-question"
                      onClick={() =>
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        })
                      }
                    >
                      <Zoom>
                        <div className="card question-card flex-column">
                          <Container>
                            <Row>
                              <Col xs={8}>
                                <h3>{item.title}</h3>
                                <h6>{item.question}</h6>
                              </Col>
                              <Col xs={4} className="date-comp">
                                <FcCalendar
                                  style={{
                                    marginLeft: "5px",
                                    fontSize: "15px",
                                  }}
                                />
                                <h5 style={{ margin: 0, fontSize: "16px" }}>
                                  {new Date(item.created_at).toLocaleDateString(
                                    "ar-EG",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    }
                                  )}
                                </h5>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </Zoom>
                    </Link>
                  ))
                ) : (
                  <p className="no-news">لاتوجد اسئلة</p>
                )}
              </div>
              {totalCount > perPage && (
            <Pagination
              getPage={getPage}
              totalCount={totalCount}
              perPage={perPage}
            />
          )}
            </>
          )}
        </Container>
      </div>
    </>
  );
}

export default Competition;
