import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ContactUs.css";
import { IoIosCall } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Button } from "antd";
import { Zoom } from "react-reveal";

function ContactUs() {
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
    content: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const handleContactChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleSendMessage = () => {
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/TDAWL/messages/store`,
        contactInfo
      )
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setContactInfo({ name: "", email: "", phone: "", content: "" });
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
  };

  const [info, setInfo] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/content/contacts`,
        headers: "application/json",
      });
      setInfo(result.data.data);
    })();
  }, []);

  return (
    <>
      <div className="contact-us-header">
        <div className="transperant-bg">
          <div className="d-flex justify-content-center align-items-center head-transp">
            ABC تداول
          </div>
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>اتصل بنا</span>
          </h5>
        </div>
      </div>
      <div className="contact-us">
        <Container className="container-body">
          <Row className="align-items-center py-5">
            <Col
              md={7}
              className="d-flex align-items-start justify-content-center flex-column"
            >
              <Zoom>
                <div className="pb-5 contact-info">
                  <h6>اتصل بنا</h6>
                  <h4>نود أن تتواصل معنا</h4>
                </div>
                <div className="d-flex justify-content-center align-items-center my-3">
                  <IoIosCall className="information-icons" />
                  <h6>{ info.phone}</h6>
                </div>
                <div className="d-flex justify-content-center align-items-center my-3">
                  <FaEnvelope className="information-icons2" />
                  <h6>{ info.email}</h6>
                </div>
                <div className="d-flex justify-content-center align-items-center my-3">
                  <MdLocationOn className="information-icons" />
                  <h6>{ info.address}</h6>
                </div>
              </Zoom>
            </Col>
            <Col md={5}>
              <Zoom>
                <div
                  style={{
                    margin: "30px 0",
                  }}
                  className="freelogin-sec "
                >
                  <h5>تواصل معنا </h5>
                  <div className="freelogin-inputs problems">
                    <input
                      name="name"
                      value={contactInfo.name}
                      type="text"
                      placeholder="الاسم"
                      onChange={handleContactChange}
                    />
                    <input
                      name="email"
                      value={contactInfo.email}
                      type="email"
                      placeholder="البريد الالكتروني"
                      onChange={handleContactChange}
                    />
                    <input
                      name="phone"
                      value={contactInfo.phone}
                      type="number"
                      placeholder="رقم الجوال"
                      onChange={handleContactChange}
                    />
                    <textarea
                      name="content"
                      value={contactInfo.content}
                      onChange={handleContactChange}
                      id=""
                      cols="30"
                      rows="8"
                      placeholder="اكتب هنا"
                    ></textarea>
                    <Button
                      onClick={handleSendMessage}
                      htmlType="submit"
                      loading={submitting}
                      type="button"
                    >
                      {!submitting && "ارسال"}
                    </Button>
                  </div>
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactUs;
