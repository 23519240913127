import React from "react";
import "./CopyRight.css";

function CopyRight() {
  return (
    <div className="copy-right">
      <h6>جميع الحقوق محفوظة لدى ABC TDAWAL</h6>
    </div>
  );
}

export default CopyRight;
