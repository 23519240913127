import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./RegisterSteps.css";
import { GiNotebook } from "react-icons/gi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaMoneyBillAlt } from "react-icons/fa";
import { Fade, Zoom } from "react-reveal";

function RegisterSteps() {
  return (
    <div className="register-steps">
      <div className="">
        <Container>
          <h5>.:: خطوات التسجيل ::.</h5>
          <Zoom>
            <Row style={{ paddingBottom: "20px" }}>
              <Col className="col-steps">
                <div className="icon-div">
                  <lord-icon
                    src="https://cdn.lordicon.com/fyuvwkhk.json"
                    trigger="loop"
                    colors="primary:white,secondary:white"
                    style={{ width: "150px", height: "150px" }}
                  ></lord-icon>
                </div>
                <h6>بيانات التسجيل</h6>
              </Col>
              <Col className="col-steps">
                <div className="icon-div">
                  <lord-icon
                    src="https://cdn.lordicon.com/rvulxkpr.json"
                    trigger="loop"
                    colors="primary:white,secondary:white"
                    style={{ width: "150px", height: "150px" }}
                  ></lord-icon>
                </div>
                <h6> التواصل المباشر مع المستشار الخاص</h6>
              </Col>
              <Col className="col-steps diff">
                <div className="icon-div">
                  <lord-icon
                    src="https://cdn.lordicon.com/hyymuwkm.json"
                    trigger="loop"
                    colors="primary:white,secondary:white"
                    style={{ width: "150px", height: "150px" }}
                  ></lord-icon>
                </div>
                <h6>استمتع بفترة مجانية مميزة</h6>
              </Col>
            </Row>
          </Zoom>
        </Container>
      </div>
    </div>
  );
}

export default RegisterSteps;
