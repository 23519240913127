import axios from "axios";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ContactComp.css";
import { toast } from "react-toastify";
import { Button } from "antd";
import { Fade ,Zoom} from "react-reveal";

function ContactComp() {
  const [peeps, setPeeps] = useState({ name: "", phone: "", content: "" });
  const [submitting, setSubmitting] = useState(false);

  const handleChange = (e) => {
    setPeeps({ ...peeps, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "El Messiri" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleSendPeeps = () => {
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/TDAWL/complaint/store`, peeps)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setPeeps({ name: "", phone: "", content: "" });
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
  };
  return (
    <div className="contact-comp">
      <div className="">
        <Container>
          <Row className="align-items-center flex-column  ">
            <Col xs={12} md={12} className="text-center">
              <Fade>
                <h6>اتصل بنا</h6>
                <h4>نود أن تتواصل معنا</h4>
              </Fade>
            </Col>
            <Col xs={12} md={12}>
              <Zoom>
                <div
                  style={{
                    margin: "30px 0",
                  }}
                  className="freelogin-sec "
                >
                  <h5>للشكاوي والمقترحات </h5>
                  <div className="freelogin-inputs problems">
                    <input
                      name="name"
                      value={peeps.name}
                      onChange={handleChange}
                      type="text"
                      placeholder="الاسم"
                    />
                    <input
                      name="phone"
                      value={peeps.phone}
                      onChange={handleChange}
                      type="number"
                      placeholder="رقم الجوال"
                    />
                    <textarea
                      name="content"
                      value={peeps.content}
                      onChange={handleChange}
                      id=""
                      cols="30"
                      rows="8"
                      placeholder="اكتب هنا"
                    ></textarea>
                    <Button
                      htmlType="submit"
                      loading={submitting}
                      onClick={handleSendPeeps}
                      type="button"
                    >
                      {!submitting && "ارسال"}
                    </Button>
                  </div>
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ContactComp;
