import axios from "axios";
import React, { useState, useEffect } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./TrainingModal.css";
import { toast } from "react-toastify";
import { Button } from "antd";

function TrainingModal({ show, setShow, setIsLoggedIn, type }) {
  const [register, setRegister] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [marketInfo, setMarketInfo] = useState([]);
    const [packageInfo, setPackageInfo] = useState([]);

  const handleClose = () => {
    setShow(false);
    setRegister(true);
  };

  const [registerForm, setRegisterForm] = useState({
    name: "",
    email: "",
      phone: "",
      market_id: "",
      bouquet_id: "",
    level: "",
    registration_type: type,
  });

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
    
  const marketId = localStorage.getItem("tadawl-marketId")

  const handleLoginChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
  };

  const handleRegisterChange = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
  };

  const successNotify = (msg) => {
    toast.success(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Cairo" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const ErrorNotify = (msg) => {
    toast.error(
      <div className="d-flex justify-content-around align-items-center">
        <h6 style={{ fontFamily: "Cairo" }}>{msg}</h6>
      </div>,
      {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleLogin = () => {
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, loginForm)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.message);
        setLoginForm({ email: "", password: "" });
        localStorage.setItem("thiqa-userId", res.data.success.user.id);
        localStorage.setItem("thiqa-userName", res.data.success.user.name);
        localStorage.setItem(
          "thiqa-marketId",
          res.data.success.user.market[0].id
        );
        localStorage.setItem("thiqa-userToken", res.data.success.token);
        localStorage.setItem("thiqa-userImg", res.data.success.user.image);
        setShow(false);
        setIsLoggedIn(localStorage.getItem("thiqa-userId"));
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
  };

  const handleRegister = () => {
    setSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/register`, registerForm)
      .then((res) => {
        setSubmitting(false);
        successNotify(res.data.success);
        setRegisterForm({
            name: "",
            email: "",
              phone: "",
              market_id: "",
              bouquet_id: "",
          level: "",
          registration_type: type,
        });

        setShow(false);
      })
      .catch((error) => {
        setSubmitting(false);
        ErrorNotify(error?.response?.data?.message);
      });
    };
    
    useEffect(() => {
        (async () => {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/homepage/markets`,
            headers: "application/json",
          });
          const thiqaMarkets = result.data.data.filter((item) => item.web_id === 1);
    
          setMarketInfo(thiqaMarkets);
        })();
      }, []);
    
      useEffect(() => {
        (async () => {
          const result = await axios({
            method: "GET",
            url: `${process.env.REACT_APP_BASE_URL}/package/index/${marketId}`,
            headers: "application/json",
          });
          setPackageInfo(result.data.data);
        })();
      }, []);
    
    console.log(registerForm)

  return (
    <>
      <Modal
        style={{ fontFamily: "El Messiri" }}
        className="modal-parent"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton onClick={() => setRegister(true)}>
          <Modal.Title>تسجيل الدخول</Modal.Title>
        </Modal.Header>
        {register ? (
          <Modal.Body style={{ backgroundColor: "rgba(225, 223, 223, 0.59)" }}>
            <div className="modal-inputs d-flex justify-content-center align-items-between flex-column">
              <input
                onChange={handleLoginChange}
                name="email"
                value={loginForm.email}
                type="email"
                placeholder="البريد الالكتروني"
              />
              <input
                onChange={handleLoginChange}
                name="password"
                value={loginForm.password}
                type="password"
                placeholder="كلمة المرور"
              />
              <Button
                htmlType="submit"
                loading={submitting}
                onClick={handleLogin}
              >
                {!submitting && "تسجيل دخول"}
              </Button>
              <label className="mx-auto my-4">
                ليس لديك حساب ؟
                <span
                  style={{
                    cursor: "pointer",
                    padding: "0 10px",
                    fontWeight: "700",
                    color: "var(--main-color)",
                  }}
                  onClick={() => setRegister(false)}
                >
                  انشاء الحساب
                </span>
              </label>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body style={{ backgroundColor: "rgba(225, 223, 223, 0.59)" }}>
            <div className="register-inputs">
              <Row>
                <Col xs={12}>
                  <div>
                    <input
                      name="name"
                      value={registerForm.name}
                      onChange={handleRegisterChange}
                      type="text"
                      placeholder="الاسم"
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <input
                    name="email"
                    value={registerForm.email}
                    onChange={handleRegisterChange}
                    type="email"
                    placeholder="البريدالالكتروني"
                  />
                </Col>
                <Col xs={12}>
                  <input
                    name="phone"
                    value={registerForm.phone}
                    onChange={handleRegisterChange}
                    type="number"
                    placeholder="رقم الجوال"
                  />
                </Col>
                <Col xs={12}>
                  <select
                    name="market_id"
                    value={registerForm.market_id}
                    onChange={handleRegisterChange}
                  >
                    <option value="" disabled>
                      اختر السوق
                    </option>
                    {marketInfo.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xs={12}>
                  <select
                    name="bouquet_id"
                    value={registerForm.bouquet_id}
                    onChange={handleRegisterChange}
                  >
                    <option value="" disabled>
                      نوع الباقة
                    </option>
                    {packageInfo.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.days} - يوم
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xs={12}>
                  <select
                    name="level"
                    value={registerForm.level}
                    onChange={handleRegisterChange}
                  >
                    <option value="" disabled>
                      اختر المستوي
                    </option>
                    <option value="1 ">المستوي الاول</option>
                    <option value="2">المستوي الثاني</option>
                  </select>
                </Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center">
                <Button
                  htmlType="submit"
                  loading={submitting}
                  onClick={handleRegister}
                >
                  {!submitting && "تسجيل"}
                </Button>
              </div>
              <label className="mx-auto my-4 d-flex justify-content-center align-items-center">
                لديك حساب بالفعل ؟
                <span
                  style={{
                    cursor: "pointer",
                    padding: "0 10px",
                    fontWeight: "700",
                    color: "var(--main-color)",
                  }}
                  onClick={() => setRegister(true)}
                >
                  تسجيل الدخول
                </span>
              </label>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
}

export default TrainingModal;
