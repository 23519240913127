import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NotFound.css";

function NotFound() {
  return (
    <Container className="notfound d-flex align-items-center justify-content-center">
      <Row>
        <Col xs={12}>
          <div class="hero-body text-center">
            <div class="not-found">
              <h1>404</h1>
              <h2>عفوًا ، لا يمكن العثور على الصفحة التي تبحث عنها!</h2>
              <div className="btn">
                <Link
                  to="/"
                  className="btn-notfound"
                >
                  العودة إلى الصفحة الرئيسية
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NotFound;
