import React from "react";
import { Link } from "react-router-dom";
import AdvantageTrust from "../../Components/AdvantageTrust/AdvantageTrust";
import TrustInfo from "../../Components/TrustInfo/TrustInfo";
import "./About.css";

function About() {
  return (
    <>
      <div className="about-header">
        <div className="transperant-bg">
          <div className="d-flex justify-content-center align-items-center head-transp">
            ABC تداول
          </div>
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            / <span>عن ABC تداول</span>
          </h5>
        </div>
      </div>
      <div className="about">
        <AdvantageTrust />

        <TrustInfo />
      </div>
    </>
  );
}

export default About;
