import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBarr from "./Components/Utality/NavBar/NavBarr";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./Pages/Main/Main";
import About from "./Pages/About/About";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PerformanceRate from "./Pages/PerformanceRate/PerformanceRate";
import Packages from "./Pages/Packages/Packages";
import Rating from "./Pages/TrustRate/TrustRate";
import News from "./Pages/News/News";
import YourIssue from "./Pages/YourIssue/YourIssue";
import Services from "./Pages/Services/Services";
import HeadBar from "./Components/Utality/HeadBar/HeadBar";
import NavBarLogin from "./Components/Utality/NavBarLogin/NavBarLogin";
import { useState } from "react";
import CopyRight from "./Components/CopyRight/CopyRight";
import Footer from "./Components/Utality/Footer/Footer";
import ClientLogin from "./Components/ClientLogin/ClientLogin";
import PerformanceDetails from "./Pages/PerformanceDetails/PerformanceDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./Pages/NotFound/NotFound";
import NewsDetails from "./Pages/NewsDetails/NewsDetails";
import Competition from "./Pages/Competition/Competition";
import CompetitionDetails from "./Pages/CompetitionDetails/CompetitionDetails";
import { FaWhatsapp } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import logImg from "./assests/abc-logo.png";
import ReactWhatsapp from "react-whatsapp";
import Training from "./Pages/Training/Training";
import SideBar from "./Components/SideBar/SideBar";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("tadawl-userId")
  );

  const [whatsShow, setWhatsShow] = useState(false);
  const [number, setNumber] = useState("+966541638879");
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [hideWhats, setHideWhats] = useState(true)

  const style2 = {
    height: 55,
    width: 55,
    lineHeight: "50px",
    borderRadius: 50,
    backgroundColor: "#25d366",
    color: "white",
    textAlign: "center",
    fontSize: 38,
    border: "1px solid #25d366",
    boxShadow: "rgb(0 0 0 / 15%) 0px 4px 12px 0px",
  };


  return (
    <div className="App">
      <BrowserRouter>
        {/* <HeadBar /> */}
        {isLoggedIn ? (
          <NavBarr setIsLoggedIn={setIsLoggedIn} />
        ) : (
          <NavBarLogin
            refresh={refresh}
            setRefresh={setRefresh}
            setIsLoggedIn={setIsLoggedIn}
          />
        )}
        <div className="content-grid">
          <div style={{ paddingLeft: "0" }}>
            <SideBar />
          </div>
          <div style={{ paddingRight: "0" }}>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/news" element={<News />} />
              <Route path="/trust-rate" element={<Rating />} />
              <Route
                path="/packges"
                element={<Packages setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route path="/services" element={<Services />} />
              <Route
                path="/training"
                element={<Training setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/about-us" element={<About />} />
              <Route
                path="/competition"
                element={<Competition setIsLoggedIn={setIsLoggedIn} />}
              />
              <Route path="/performance-rate" element={<PerformanceRate />} />
              <Route path="/your-issue" element={<YourIssue />} />
              <Route
                path="/performance-details/:id"
                element={<PerformanceDetails refresh={refresh} />}
              />
              <Route path="/news-details/:newsId" element={<NewsDetails />} />
              <Route
                path="/competition/:questionId"
                element={<CompetitionDetails />}
              />
              <Route path="*" element={<NotFound />} />
              <Route path="/marketer/login/:marketerId" element={<ClientLogin setHideWhats={setHideWhats} />} />
            </Routes>
          </div>
        </div>

        <Footer />
        <CopyRight />
      </BrowserRouter>
      <ToastContainer style={{ marginTop: "42px", textAlign: "center" }} />

      {hideWhats ? <> <button
        onClick={() => setWhatsShow(!whatsShow)}
        className="whatsapp-icon show"
      >
        <div style={style2}>
          <FaWhatsapp />
        </div>
      </button>

      <div className={whatsShow ? "content" : "hide"}>
        <div className="d-flex align-items-center justify-content-around head-whats">
          <h4>ABC | تداول</h4>
          <img src={logImg} alt="" />
        </div>

        <div className="whats-half">
          <p>أهلاً بك! 🤝</p>
          <p> كيف يمكن أن نساعدك؟</p>
        </div>

        <div className="library d-flex justify-content-around align-items-center">
          <ReactWhatsapp number={number} message={message}>
            <MdSend className="send-ico" />
          </ReactWhatsapp>
          <input
            id="message"
            placeholder="اكتب رسالتك..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div></>:null}
     
    </div>
  );
}

export default App;
