import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import "./PerformanceDetails.css";
import RecomPagination from "./../../Components/RecomPagination/RecomPagination";
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';


function PerformanceDetails({ refresh }) {
  const marketId = localStorage.getItem("tadawl-marketId");

  const { id } = useParams();

  const [dataDetails, setDataDetails] = useState([]);
  const [dataDetailsCounts, setDataDetailsCounts] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [perPage, setPerPage] = useState();

  const [filter, setFilter] = useState({ year: "", month: "" });

  const handleFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  console.log(filter);

  const getRecomData = async () => {
    const result = await axios({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/TDAWL/performance-rate/rate/${id}/${marketId}`,
      headers: "application/json",
    });
    setDataDetails(result.data.response.data.data);
    setDataDetailsCounts(result.data.response);
    setTotalCount(result.data.response.data.total);
    setPerPage(result.data.response.data.per_page);
  };

  useEffect(() => {
    getRecomData();
  }, [refresh]);

  const getPage = async (page) => {
    const result1 = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/TDAWL/performance-rate/rate/${id}/${marketId}?page=${page}`
    );
    setDataDetails(result1.data.response.data.data);
    setTotalCount(result1.data.response.data.total);
    setPerPage(result1.data.response.data.per_page);
  };

  const getSearch = async (filter) => {
    console.log(filter);
    if (filter.year === "" && filter.month === "") {
      getRecomData();
    } else {
      const result2 = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/TDAWL/performance-rate/rate/${id}/${marketId}?year=${filter.year}&month=${filter.month}`
      );
      setDataDetails(result2.data.response.data.data);
      setTotalCount(result2.data.response.data.total);
    setPerPage(result2.data.response.data.per_page);
      setDataDetailsCounts(result2.data.response);
    }
  };

  ChartJS.register(ArcElement, Tooltip, Legend);
  ChartJS.defaults.font.family = "'El Messiri', sans-serif";
const data = {
    labels: ['عدد التوصيات المحققة', 'عدد التوصيات ايقاف الخسارة'],
    datasets: [
      {
        data: [`${dataDetailsCounts.totalCheckedRecommendations}`, `${dataDetailsCounts.totalStopLossRecommendations}`],
        backgroundColor: [
          'rgba(75, 192, 192, 0.5)',
          'rgba(255, 99, 132, 0.5)',
        
        ],
        borderColor: [
          'rgba(75, 192, 192, 0.5)',
          'rgba(255, 99, 132, 0.5)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="performance-details-header">
        <div className="transperant-bg">
          <h5>
            <Link className="back-home" to="/">
              الرئيسية
            </Link>
            /
            <Link className="back-home" to="/performance-rate">
              معدل الأداء
            </Link>
            / <span>مضاربية</span>
          </h5>
        </div>
      </div>
      <div className="performance-details">
        <Container className="container-body">
          <h4>اختر تاريخ البحث</h4>
          <Row className="align-items-center justify-content-between">
            <Col md={3}>
              <select
                value={filter.year}
                name="year"
                onChange={handleFilterChange}
              >
                <option value="">العام-الكل</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
              </select>
            </Col>
            <Col md={3}>
              <select
                value={filter.month}
                name="month"
                onChange={handleFilterChange}
              >
                <option value="">الشهر-الكل</option>
                <option value="1">يناير</option>
                <option value="2">فبراير</option>
                <option value="3">مارس</option>
                <option value="4">ابريل</option>
                <option value="5">مايو</option>
                <option value="6">يونيه</option>
                <option value="7">يوليو</option>
                <option value="8">اغسطس</option>
                <option value="9">سبتمبر</option>
                <option value="10">اكتوبر</option>
                <option value="11">نوفمبر</option>
                <option value="12">ديسمبر</option>
              </select>
            </Col>
            <Col md={3}>
              <button
                style={{ color: "white" }}
                onClick={() => getSearch(filter)}
              >
                بحث
              </button>
            </Col>
          </Row>
          <div className="d-flex align-items-center justify-content-between">
          <div className="result-recommendation">
            <h2>نتائج جميع التوصيات</h2>
            <h3>
              إجمالي عدد التوصيات: <span>{dataDetailsCounts.totalCount}</span>
              توصية
            </h3>
            <h3>
              عدد التوصيات المحققة:
              <span>{dataDetailsCounts.totalCheckedRecommendations}</span> توصية
            </h3>
            <h3>
              عدد التوصيات ايقاف الخسارة:
              <span>{dataDetailsCounts.totalStopLossRecommendations}</span>
              توصية
            </h3>
            <h3>
              متوسط ربح التوصية الواحدة:
              <span>
                {Math.round(dataDetailsCounts.$AverageRecommendations)}
              </span>
              %
            </h3>
            <h3>
              عدد التوصيات المفتوحة:
              <span>{dataDetailsCounts.newRecommendations}</span>
              توصية
            </h3>
          </div>
<div className="pie-chart">
  {dataDetailsCounts.totalCheckedRecommendations === 0 && dataDetailsCounts.totalStopLossRecommendations === 0 ? null :<Pie data={data} />}
</div>
         
          </div>
      

          <div className="card details-card-table">
            <Table bordered responsive>
              <thead>
                <tr className="text-center">
                  <th>كود السهم </th>
                  <th>القطاع</th>
                  <th>السهم</th>
                  <th>قائمة الشرعية</th>
                  <th>تاريخ الإنشاء</th>
                  <th>تاريخ البيع</th>
                  <th>سعر الشراء</th>
                  <th>سعر البيع</th>
                  <th>سعر وقف خسائر</th>
                  <th>تاريخ التحقيق</th>
                  <th>الحالة</th>
                </tr>
              </thead>
              <tbody>
                {dataDetails.length !== 0 ? (
                  dataDetails.map((item) => (
                    <tr
                      key={item.id}
                      className={
                        item.condition === "رابحه"
                          ? "done-recom text-center"
                          : "text-center"
                      }
                    >
                      <td>{item.stock.code}</td>
                      <td>{item.stock.sector}</td>
                      <td>{item.stock.name}</td>
                      <td>{item.stock.type}</td>
                      <td>
                        {new Date(item.date_of_purchase).toLocaleDateString({
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </td>
                      <td>
                        {new Date(item.date_of_sale).toLocaleDateString({
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </td>
                      <td>{item.purchasing_price}</td>
                      <td>{item.selling_price}</td>
                      <td>{item.stop_loss_price}</td>
                      <td>
                        {new Date(item.updated_at).toLocaleDateString({
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </td>
                      <td>{item.condition}</td>
                    </tr>
                  ))
                ) : (
                  <p className="no-total">لاتوجد أسهم</p>
                )}
              </tbody>
            </Table>
          </div>
          {totalCount > perPage && (
            <RecomPagination
              getPage={getPage}
              totalCount={totalCount}
              perPage={perPage}
            />
          )}
        </Container>
      </div>
    </>
  );
}

export default PerformanceDetails;
