import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./FreeLogin.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { toast } from "react-toastify";
import { RiLockPasswordFill, RiErrorWarningFill } from "react-icons/ri";
import { AiFillCheckCircle } from "react-icons/ai";
import Parse from "html-react-parser";
import { Button } from "antd";
import { Zoom } from "react-reveal";
import freeImg from "../../assests/bannar_16.jpg";
import FreeLoginModal from "../FreeLoginModal/FreeLoginModal";
import Countdown from "react-countdown";

function FreeLogin() {
  const [dataInfo, setDataInfo] = useState([]);

  const [show3, setShow3] = useState(false);

    const [offer, setOffer] = useState({});

  const [activeTimer, setActiveTimer] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleShow = () => setShow3(true);

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/about-as`,
        headers: "application/json",
      });
      setDataInfo(result.data.data);
    })();
  }, []);

    useEffect(() => {
      (async () => {
        setLoading(true);
        const result = await axios({
          method: "GET",
          url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/offer`,
          headers: "application/json",
        });
        setOffer(result.data.data);
        setLoading(false);
      })();
    }, []);

    function isAfter(date1, date2) {
      return date1 > date2;
    }

    const d1 = new Date();
    const d2 = new Date(offer.end_date);

  return (
    <div className="free-login">
      <Container>
        <Row className="align-items-center">
          <Col className="trust-sec" xs={12} md={6}>
            <Zoom>
              <h4>ABC TADAWAL</h4>
              <h6 className="text1">
                دليلك الاول و مستشارك الخاص في عالم الاسواق الماليه و التداول
              </h6>
              <p className="text2">{Parse(`${dataInfo[0]}`)}</p>
              <div className="d-flex justify-content-center">
                <button onClick={handleShow} className="btn-most">
                  تسجيل مجاني
                </button>
              </div>
            </Zoom>
          </Col>
          <Col xs={12} md={6}>
            <Zoom>
              <div>
                <>
                  {loading ? <div className="abc-loading mt-5">
            <lord-icon
              src="https://cdn.lordicon.com/avytqtql.json"
              trigger="loop"
              colors="primary:#329292,secondary:#d1f3fa"
              style={{ width: "60px", height: "60px" }}
            ></lord-icon>
          </div>:   <div className="countdown d-flex align-items-center justify-content-center">
                    {isAfter(d2, d1) && activeTimer ? (
                      <Countdown
                        autoStart={true}
                        onComplete={() => setActiveTimer(false)}
                        date={
                          Date.now() +
                          Math.abs(
                            new Date().getTime() -
                              new Date(offer.end_date).getTime()
                          )
                        }
                      />
                    ) :  (
                      <div className="d-flex align-items-center justify-content-center">
                        <p
                          style={{
                            color: "white",
                            fontSize: "20px",
                            margin: 0,
                          }}
                        >
                          تم انتهاء العرض . ترقبو العروض القادمة
                        </p>
                        <lord-icon
                          src="https://cdn.lordicon.com/osvvqecf.json"
                          trigger="loop"
                          colors="outline:#329292,primary:#ffffff,secondary:#ebe6ef,tertiary:#ffffff"
                          style={{ width: "60px", height: "60px" }}
                        ></lord-icon>
                      </div>
                    )}
                  </div>}
                 
                </>
                <img
                  style={{
                    width: "100%",
                    height: "420px",
                    borderRadius: "0 0 10px 10px",
                  }}
                  src={offer.image}
                  alt=""
                />
              </div>
            </Zoom>
          </Col>
        </Row>
        <FreeLoginModal show3={show3} setShow3={setShow3} />
      </Container>
    </div>
  );
}

export default FreeLogin;
