import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import logo from "../../../assests/abc-logo.png";
import "./NavBarr.css";
import { Link } from "react-router-dom";
import { BiLogOut } from "react-icons/bi";
import { MdMenu } from "react-icons/md";
import axios from "axios";

function NavBarr({ setIsLoggedIn }) {
  const [selected, setSelected] = useState();
  const [marketInfo, setMarketInfo] = useState([]);
  const [activeLink, setActiveLink] = useState(1);

  const [hide, setHide] = useState(true);

  const handleHide = () => setHide(!hide);

  const handleClickToggle = () => {
    setHide(!hide);
  };

  const handleMarketChange = (e) => {
    setSelected(e.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(localStorage.getItem("tadawl-userId"));
  };

  // localStorage.setItem("tadawl-marketId", selected);

  const user = localStorage.getItem("tadawl-userName");

  const userImg = localStorage.getItem("tadawl-userImg");

  useEffect(() => {
    (async () => {
      const result = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_BASE_URL}/TDAWL/homepage/markets`,
        headers: "application/json",
      });

      setMarketInfo(result.data.data);
      setSelected(result.data.data[0]?.id);
    })();
  }, []);

  return (
    <Navbar
      style={{
        backgroundColor: "var(--second-color)",
        borderBottom: "1px solid var(--main-color)",
      }}
      className="navbar-trust"
      collapseOnSelect
      expand="lg"
      variant="dark"
    >
      <Container style={{ margin: "0 30px" }}>
        <Link
          className="logo-link"
          to="/"
          onClick={() => {
            setHide(!hide);
            setActiveLink(1);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img className="logo" src={logo} alt="" />
        </Link>
        <MdMenu onClick={handleClickToggle} className="toggle-btn" />
        <Navbar.Collapse className={hide ? "hide" : "show"}>
          <Nav className="links-route">
            <div className="nav-route-top">
              <div className={activeLink === 1 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(1);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/"
                  className="links"
                >
                  الرئيسية
                </Link>
              </div>
              <div className={activeLink === 6 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(6);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/services"
                  className="links"
                >
                  الخدمات
                </Link>
              </div>
              <div className={activeLink === 2 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(2);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/packges"
                  className="links"
                >
                  الباقات
                </Link>
              </div>
              <div className={activeLink === 3 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(3);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/performance-rate"
                  className="links"
                >
                  معدل الأداء
                </Link>
              </div>
              {/* <div className={activeLink === 4 ? "active-link" : "links-div"}>
        <Link
          onClick={() => {
            setHide(!hide);
            setActiveLink(4);
          }}
          to="/your-issue"
          className="links"
        >
          مشكلتك
        </Link>
      </div> */}
              <div className={activeLink === 7 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(7);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/news"
                  className="links"
                >
                  الاخبار
                </Link>
              </div>
              <div className={activeLink === 5 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(5);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/trust-rate"
                  className="links"
                >
                  التقييم
                </Link>
              </div>

              <div className={activeLink === 9 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(9);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/competition"
                  className="links"
                >
                  اراء محللين
                </Link>
              </div>
              <div className={activeLink === 4 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(4);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/training"
                  className="links"
                >
                  تدريب
                </Link>
              </div>
              <div className={activeLink === 8 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(8);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/about-us"
                  className="links"
                >
                  عن ABC تداول
                </Link>
              </div>
              <div className={activeLink === 10 ? "active-link" : "links-div"}>
                <Link
                  onClick={() => {
                    setHide(!hide);
                    setActiveLink(10);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  to="/contact-us"
                  className="links"
                >
                  اتصل بنا
                </Link>
              </div>
            </div>
          </Nav>
          <Nav>
            <div className="flags ms-3">
              {/* <select
                name=""
                id=""
                value={selected}
                onChange={handleMarketChange}
                n
              >
                {marketInfo.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select> */}
            </div>

            <div>
              <Dropdown>
                <Dropdown.Toggle className="dropdown-user">
                  <img className="topbarImg" src={userImg} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu">
                  <div className="text-center">
                    <h6
                      style={{
                        color: "var(--second-color)",
                        padding: "10px 5px",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      {user}
                    </h6>
                  </div>
                  <Dropdown.Item className="dropdown-items">
                    <button className="logout-btn" onClick={handleLogout}>
                      <BiLogOut className="mx-1" />
                      تسجيل خروج
                    </button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBarr;
