import React from "react";
import ReactPaginate from "react-paginate";
import "./RecomPagination.css";


function RecomPagination({ getPage,  totalCount , perPage }) {
  const handlePageClick = (data) => {
    getPage(data.selected + 1);
  };

  const pageCount = Math.ceil(totalCount / perPage);

  // console.log(pageCount)
  // console.log(totalCount)
  // console.log(perPage)

  return (
    <div className="pagination-parent">
      <ReactPaginate
        breakLabel="..."
        nextLabel={"التالي"}
        onPageChange={handlePageClick}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        previousLabel={"السابق"}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default RecomPagination;
